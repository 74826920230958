<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512.002 512.002"
    style="enable-background:new 0 0 512.002 512.002;"
    xml:space="preserve"
  >
    <path
      style="fill:#FFC850;"
      d="M259.743,12.544l31.386-11.86c3.501-1.323,7.441-0.699,10.361,1.641l26.183,20.979
	c2.012,1.612,4.543,2.435,7.118,2.313l33.515-1.581c3.739-0.176,7.292,1.635,9.347,4.763l18.42,28.043
	c1.415,2.155,3.568,3.719,6.055,4.399l32.363,8.852c3.61,0.988,6.43,3.807,7.417,7.417l8.852,32.363
	c0.68,2.487,2.244,4.64,4.399,6.055l28.043,18.42c3.128,2.055,4.939,5.608,4.763,9.347l-1.581,33.515
	c-0.121,2.576,0.701,5.106,2.313,7.118l20.979,26.185c2.34,2.921,2.964,6.86,1.641,10.361l-11.86,31.386
	c-0.911,2.411-0.911,5.072,0,7.484l11.86,31.386c1.323,3.501,0.699,7.441-1.641,10.361l-20.979,26.185
	c-1.612,2.012-2.435,4.543-2.313,7.118l1.581,33.515c0.176,3.739-1.635,7.292-4.763,9.347l-28.043,18.42
	c-2.155,1.415-3.719,3.568-4.399,6.055l-8.852,32.363c-0.988,3.61-3.807,6.43-7.417,7.417l-32.363,8.852
	c-2.487,0.68-4.64,2.244-6.055,4.399l-18.42,28.043c-2.055,3.128-5.608,4.939-9.347,4.763l-33.515-1.581
	c-2.574-0.121-5.106,0.701-7.118,2.313l-26.185,20.979c-2.921,2.34-6.86,2.964-10.361,1.641l-31.386-11.86
	c-2.411-0.911-5.072-0.911-7.484,0l-31.386,11.86c-3.501,1.323-7.441,0.699-10.361-1.641l-26.185-20.979
	c-2.012-1.612-4.543-2.435-7.118-2.313l-33.515,1.581c-3.739,0.176-7.292-1.635-9.347-4.763l-18.42-28.043
	c-1.415-2.155-3.568-3.719-6.055-4.399l-32.363-8.852c-3.61-0.988-6.43-3.807-7.417-7.417l-8.852-32.363
	c-0.68-2.487-2.244-4.64-4.399-6.055l-28.043-18.421c-3.128-2.055-4.939-5.608-4.763-9.347l1.581-33.515
	c0.122-2.575-0.701-5.106-2.313-7.118L2.326,301.488c-2.34-2.921-2.964-6.86-1.641-10.361l11.86-31.386
	c0.911-2.411,0.911-5.072,0-7.484l-11.86-31.386c-1.323-3.501-0.699-7.441,1.641-10.361l20.979-26.183
	c1.612-2.012,2.435-4.543,2.313-7.118l-1.581-33.515c-0.176-3.739,1.635-7.292,4.763-9.347l28.043-18.42
	c2.155-1.415,3.719-3.568,4.399-6.055l8.852-32.363c0.988-3.61,3.807-6.43,7.417-7.417l32.363-8.852
	c2.487-0.68,4.64-2.244,6.055-4.399l18.42-28.043c2.055-3.128,5.608-4.939,9.347-4.763l33.515,1.581
	c2.575,0.122,5.106-0.701,7.118-2.313l26.185-20.979c2.921-2.34,6.86-2.964,10.361-1.641l31.386,11.86
	C254.67,13.455,257.332,13.455,259.743,12.544z"
    />
    <path
      style="fill:#E1A546;"
      d="M335.9,442.429c-147.089,0-266.329-119.24-266.329-266.329c0-39.393,8.621-76.751,23.971-110.394
	l-16.031,4.386c-3.61,0.988-6.43,3.807-7.417,7.417l-8.853,32.363c-0.68,2.487-2.244,4.64-4.399,6.056l-28.044,18.42
	c-3.128,2.055-4.939,5.608-4.763,9.346l1.581,33.515c0.121,2.575-0.701,5.106-2.313,7.118L2.326,210.512
	c-2.34,2.921-2.964,6.86-1.641,10.36l11.86,31.386c0.911,2.411,0.911,5.072,0,7.484l-11.86,31.386
	c-1.323,3.501-0.699,7.441,1.641,10.361l20.979,26.185c1.612,2.012,2.435,4.543,2.313,7.118l-1.581,33.515
	c-0.176,3.739,1.635,7.292,4.763,9.347l28.043,18.42c2.154,1.415,3.719,3.568,4.399,6.055l8.853,32.363
	c0.988,3.61,3.807,6.43,7.417,7.418l32.363,8.853c2.487,0.68,4.64,2.244,6.055,4.399l18.42,28.043
	c2.055,3.128,5.608,4.938,9.347,4.763l33.514-1.581c2.576-0.121,5.107,0.701,7.119,2.313l26.185,20.979
	c2.921,2.339,6.86,2.964,10.36,1.641l31.386-11.861c2.411-0.911,5.072-0.911,7.484,0l31.386,11.861
	c3.501,1.323,7.441,0.699,10.36-1.641l26.185-20.979c2.012-1.611,4.543-2.434,7.119-2.313l33.514,1.581
	c3.739,0.176,7.292-1.635,9.347-4.763l18.42-28.043c1.415-2.154,3.568-3.719,6.055-4.399l32.363-8.853
	c3.61-0.988,6.43-3.807,7.417-7.418l4.386-16.031C412.652,433.809,375.293,442.429,335.9,442.429z"
    />
    <circle style="fill:#FFDC64;" cx="255.998" cy="255.997" r="204.185" />
    <path
      style="fill:#FFC850;"
      d="M354.171,401.689c-1.757-2.343-4.495-3.71-7.422-3.806c-109.566-3.645-197.28-93.572-197.28-204.025
	c0-29.537,6.343-57.555,17.67-82.871c3.585-8.014-5.37-15.984-12.728-11.194C98.135,136.43,62.84,202.553,70.649,276.321
	c8.81,83.219,74.039,151.434,156.782,163.944c45.671,6.905,88.48-3.106,123.836-24.335
	C356.255,412.935,357.661,406.344,354.171,401.689L354.171,401.689z"
    />
    <g>
      <path
        style="fill:#FFFFFF;"
        d="M193.858,362.531h-53.266c-4.903,0-8.878-3.975-8.878-8.878V211.611
		c0-4.903,3.975-8.878,8.878-8.878h53.266c4.903,0,8.878,3.975,8.878,8.878v142.042
		C202.736,358.557,198.761,362.531,193.858,362.531z"
      />
      <path
        style="fill:#FFFFFF;"
        d="M389.095,222.638c-1.156-11.551-11.736-19.905-23.345-19.905h-75.57
		c20.666-16.731,34.698-39.175,36.729-57.633c1.506-13.687-7.958-26.688-21.727-26.816c-12.347-0.114-22.397,9.76-22.397,22.08
		c0,7.993-17.348,31.713-49.86,42.267c-0.048,0.016-0.094,0.031-0.142,0.047c-7.605,2.516-12.292,10.208-12.292,18.218v143.878
		c0,4.903,3.975,8.878,8.878,8.878h114.505c8.844,0,17.042-6.045,18.462-14.775c1.809-11.124-6.729-20.736-17.512-20.736h7.929
		c8.844,0,17.042-6.045,18.462-14.775c1.809-11.124-6.729-20.736-17.512-20.736h7.929c8.844,0,17.042-6.045,18.462-14.775
		c1.809-11.124-6.729-20.736-17.512-20.736h4.439C380.029,247.122,390.425,235.924,389.095,222.638z"
      />
    </g>
    <g>
      <path
        style="fill:#FFFAB4;"
        d="M335.9,300.387c-4.903,0-8.878,3.975-8.878,8.878c0,4.903,3.975,8.878,8.878,8.878h8.922h7.929
		c8.844,0,17.042-6.045,18.462-14.775c0.164-1.015,0.154-1.997,0.153-2.981H335.9z"
      />
      <path
        style="fill:#FFFAB4;"
        d="M335.9,264.877c-4.903,0-8.878,3.975-8.878,8.878c0,4.903,3.975,8.878,8.878,8.878h17.8h7.929
		c8.844,0,17.042-6.045,18.462-14.775c0.164-1.015,0.154-1.997,0.153-2.981H335.9z"
      />
      <path
        style="fill:#FFFAB4;"
        d="M335.9,229.367c-4.903,0-8.878,3.975-8.878,8.878s3.975,8.878,8.878,8.878h26.677h4.439
		c10.723,0,19.555-7.646,21.628-17.755H335.9z"
      />
      <path
        style="fill:#FFFAB4;"
        d="M256.002,335.899c-9.806,0-17.755-7.949-17.755-17.755V208.752c0-3.561,2.131-6.745,5.396-8.165
		c14.532-6.323,60.259-30.663,65.074-78.458c-1.456-1.524-2.218-2.32-3.674-3.844c-12.283-0.039-22.257,9.806-22.257,22.081
		c0,7.993-17.348,31.713-49.86,42.267l0,0c-7.418,2.435-12.434,9.36-12.434,17.168v144.976c0,4.903,3.975,8.878,8.878,8.878h114.505
		c9.8,0,18.805-7.421,18.676-17.692c-0.023-0.024-0.039-0.039-0.062-0.063H256.002z"
      />
      <path
        style="fill:#FFFAB4;"
        d="M167.225,344.776c-9.806,0-17.755-7.949-17.755-17.755V202.734h-8.878
		c-4.903,0-8.878,3.975-8.878,8.878v142.042c0,4.903,3.975,8.878,8.878,8.878h53.266c4.903,0,8.878-3.975,8.878-8.878v-8.878
		H167.225z"
      />
    </g>
    <circle style="fill:#FFDC64;" cx="167.222" cy="327.018" r="13.316" />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
